import React from "react"
import { Heading } from "@chakra-ui/core"

type Props = {}

export const Heading1: React.FC<Props> = ({ children }) => {
  return (
    <Heading
      as="h1"
      fontSize={["1.6rem", "1.8rem", "2rem", "2.5rem"]}
      fontWeight="bold"
      letterSpacing="0.3rem"
      color="black"
      lineHeight={["40px", "60px"]}
      marginTop="0rem"
      paddingTop="0rem"
    >
      {children}
    </Heading>
  )
}
