import { Text } from "@chakra-ui/core"
import React from "react"

type Props = {
  text: string
}

export const SloganText: React.FC<Props> = ({ text }) => {
  return (
    <Text
      fontWeight="800"
      color="#644BFB"
      fontSize="1.3rem"
      letterSpacing="0.1rem"
      marginTop="3rem"
      paddingY="0rem"
    >
      {text}
    </Text>
  )
}
