import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

type Props = { name: string }

const OperatorImage: React.FC<Props> = ({ name }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        (edge: any) => edge.node.fluid.originalName === `${name}.jpg`
      )
      if (!image) {
        return null
      }
      return <Img fluid={image.node.fluid} alt={name} />
    }}
  />
)
export default OperatorImage
