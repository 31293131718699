import { Text } from "@chakra-ui/core"
import React from "react"

type Props = {}

export const DescText: React.FC<Props> = ({ children }) => {
  return (
    <Text fontSize="1.4rem" marginTop="3rem" color="#444444">
      {children}
    </Text>
  )
}
