import React from "react"
import { Box } from "@chakra-ui/core"

type Props = {}

export const HeaderWithColor: React.FC<Props> = ({ children }) => {
  return (
    <Box minHeight="300px" bg="bgColor" m="0rem" p="0rem">
      {children}
    </Box>
  )
}
