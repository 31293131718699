import { Box } from "@chakra-ui/core"
import React from "react"

type Props = {}

export const HeaderLeft: React.FC<Props> = ({ children }) => {
  return (
    <Box
      flex="1"
      display="flex"
      flexDir="column"
      justifyContent="center"
      textAlign={["center", "center", "center", "center", "left"]}
      paddingRight="1rem"
      marginLeft={["0rem", "0rem", "0rem", "0rem", "124px"]}
    >
      <Box
        zIndex={100}
        maxW={["100%", "100%", "100%", "100%", "95%"]}
        display="flex"
        flexDir="column"
      >
        {children}
      </Box>
      <Box
        bg="#DFDAFF"
        borderBottomRightRadius="2000px"
        borderTopRightRadius="2000px"
        width="50px"
        height="400px"
        position="relative"
        marginLeft={["0px", "0px", "0px", "-124px", "-124px"]}
        marginTop="-500px"
      ></Box>
    </Box>
  )
}
