import { Box } from "@chakra-ui/core"
import React from "react"
import OperatorImage from "../images/OperatorImage"

export const Operators = () => {
  const width = ["70px", "70px", "70px", "70px", "80px"]
  return (
    <Box
      display="flex"
      margin={[
        "3rem auto",
        "3rem auto",
        "3rem auto",
        "3rem auto",
        "3rem 1.5rem",
      ]}
      flex="1"
      justifyContent={["center", "center", "center", "center", "start"]}
      bg="white"
      borderRadius="5px"
      maxW="300px"
    >
      <Box width={width} margin="0.2rem">
        <OperatorImage name="moi" />
      </Box>
      <Box width={width} margin="0.2rem">
        <OperatorImage name="elisa" />
      </Box>
      <Box width={width} margin="0.2rem">
        <OperatorImage name="telia" />
      </Box>
      <Box width={width} margin="0.2rem">
        <OperatorImage name="dna" />
      </Box>
    </Box>
  )
}
